<template>
    <div>
        <slot :toggle-menu="toggleMenu" :toggle="toggle"></slot>
    </div>
</template>

<script>
import { ref } from "vue";
import debounce from "lodash.debounce";

export default {
    setup() {
        const toggle = ref(false);
        const isOpen = ref(false);

        const toggleMenu = (e) => {

            if (e)
                e.preventDefault();

            const nav = document.getElementById('nav-overlay');

            if (!isOpen.value) {
                isOpen.value = true;

                if (nav)
                    nav.classList.add('is-active');

                toggle.value = true;

                setTimeout(function () {
                    if (nav)
                        nav.classList.add('start');
                }, 100);

                hideBackgroundDebounced();
            }
            else {
                toggle.value = false;

                if (nav)
                    nav.classList.remove('start');

                setTimeout(function () {
                    isOpen.value = false;

                    if (nav)
                        nav.classList.remove('is-active');
                }, 400);

                hideBackgroundDebounced.cancel();
                hideBackground(false);
            }
        };

        // hides element that shouldnt be focusable during active main menu
        const hideBackground = (hide) => {
            // layout blocks
            const main = document.querySelector('.main');
            const footer = document.querySelector('.footer');

            // header top bar
            const minaSidor = document.querySelector('.header__minasidor');
            const login = document.getElementById('header-login');
            const logo = document.querySelector('.header__logo');
            const search = document.querySelector('.input-search-center')

            if (hide) {
                if (main)
                    main.style.visibility = "hidden";
                if (footer)
                    footer.style.visibility = "hidden";
                if (minaSidor)
                    minaSidor.style.visibility = "hidden";
                if (login)
                    login.style.visibility = "hidden";
                if (logo)
                    logo.style.visibility = "hidden";
                if (search)
                    search.style.visibility = "hidden";
            } else {
                if (main)
                    main.style.visibility = "";
                if (footer)
                    footer.style.visibility = "";
                if (minaSidor)
                    minaSidor.style.visibility = "";
                if (login)
                    login.style.visibility = "";
                if (logo)
                    logo.style.visibility = "";
                if (search)
                    search.style.visibility = "";
            }
        };

        const hideBackgroundDebounced = debounce(() => hideBackground(true), 600);

        return {
            toggle,
            isOpen,
            toggleMenu
        }
    }
}

</script>